body {
  margin: 1rem;
}
.MuiTableCell-root {
  display: table-cell;
  padding: 2px !important;
}
.MuiOutlinedInput-root {
  position: relative;
  border-radius: 4px;
  height: 45px !important;
}


