.login {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: #f2f2f2;
}

.login.darkMode {
  background-color: #000000;
}

.login .forgotPasswordDiv {
  text-align: right;
  margin-top: 10rem;
  margin-right: 10px;
}

.login .forgotPasswordLink {
  color: green;
  text-decoration: none;
  font-size: 0.9rem;
  cursor: pointer;
}

.login .forgotPasswordLink:hover {
  text-decoration: underline;
}

.login .loginContainer {
  flex: 6;
}

.login .loginContainer .mainDiv {
  display: flex;
  min-height: 100%;
  align-items: start;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
}

.login .loginContainer .mainDiv #loginLogo {
  max-width: 350px;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.login .loginContainer .mainDiv .cardStyle {
  width: 500px;
  border-color: white;
  background: #fff;
  padding: 36px 0;
  border-radius: 0.5rem;
  /* margin: 30px 0; */
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
}

.login .loginContainer .mainDiv .cardStyle.darkMode {
  background-color: #0a0000;
}

.login .loginContainer .mainDiv .cardStyle .formTitle {
  font-weight: 600;
  margin-top: 20px;
  color: #000000;
  font-size: 16px;
  text-align: center;
}

.login .loginContainer .mainDiv .cardStyle .formTitle.darkMode {
  color: #ffa03f;
}

.login .loginContainer .mainDiv .cardStyle .horizontalLine {
  width: 40%;
  height: 1px;
  margin: auto;
  margin-top: 1rem;
  background-color: #0a0000;
  border: none;
}

.login .loginContainer .mainDiv .cardStyle .horizontalLine.darkMode {
  background-color: #ffa03f;
}

.login .loginContainer .mainDiv .cardStyle .inputDiv {
  width: 70%;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.login .loginContainer .mainDiv .cardStyle .inputDiv .inputLabel {
  font-size: 12px;
  color: #555;
  margin-bottom: 6px;
  margin-top: 24px;
}

.login .loginContainer .mainDiv .cardStyle .inputDiv .inputLabel.darkMode {
  color: #ffa03f;
}

.login .loginContainer .mainDiv .cardStyle .inputDiv input {
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
  border: solid 1px #ccc;
  padding: 0 11px;
}

.login .loginContainer .mainDiv .cardStyle .inputDiv input:disabled {
  cursor: not-allowed;
  border: solid 1px #eee;
}

.login .loginContainer .mainDiv .cardStyle .errorMessageDiv {
  text-align: center;
  color: orangered;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.login .loginContainer .mainDiv .cardStyle .errorMessageDiv .errorMessage {
  width: 100%;
}

.login .loginContainer .mainDiv .cardStyle .buttonWrapper {
  margin-top: 2.5rem;
}

.login .loginContainer .mainDiv .cardStyle .buttonWrapper .submitButton {
  width: 70%;
  height: 40px;
  margin: auto;
  display: block;
  color: #fff;
  background-color: darkorange;
  border: 0 darkorange;
  border-radius: 1.5rem;
  font-size: 14px;
  cursor: pointer;
}

.login .loginContainer .mainDiv .cardStyle .buttonWrapper .submitButton:hover {
  background-color: #774000;
}

.login .loginContainer .mainDiv .cardStyle .buttonWrapper .submitButton #loader {
  position: absolute;
  z-index: 1;
  margin: -2px 0 0 10px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #666666;
  width: 14px;
  height: 14px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.login .loginContainer .mainDiv .cardStyle .buttonWrapper .submitButton:disabled,
.login .loginContainer .mainDiv .cardStyle .buttonWrapper button[disabled] {
  border: 1px solid #cccccc;
  background-color: #cccccc;
  color: #666666;
}
